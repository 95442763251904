
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'delete-modal'
})
export default class DeleteModal extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) errorMessage!: string

  get isOpenModel (): boolean {
    return this.value
  }

  set isOpenModel (value: boolean) {
    this.$emit('input', value)
  }

  confirmDelete () {
    this.$emit('confirm-delete')
    this.$root.$emit('bv::hide::modal', 'delete-modal', '#deleteBtn')
  }
}

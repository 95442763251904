
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'read-more-btn'
})
export default class ReadMoreBtn extends Vue {
  @Prop() readLessContent: string
  @Prop() customTabContent: string
  @Prop({ default: 150 }) charLimit: number

  expanded = false
  initialExpanded = false
  customTabContentBefore = null
  customTabContentAfter = null
  contentBefore = ""
  isCustomTab = false

  getContentBefore (): void {
    if (this.readLessContent) {
      this.contentBefore = this.readLessContent.substring(0, this.charLimit)
      this.isCustomTab = false
    } else {
      const parser = new DOMParser()
      const parsedHtml = parser.parseFromString(this.customTabContent, 'text/html')
      const beforeElement = parsedHtml.querySelector('#before')
      if (beforeElement) {
        this.customTabContentBefore = beforeElement.innerHTML
      }
      const afterElement = parsedHtml.querySelector('#after')
      if (afterElement) {
        this.customTabContentAfter = afterElement.innerHTML
      }
      this.isCustomTab = true
    }
  }

  mounted (): void {
    this.getContentBefore()
    this.expanded = this.readLessContent?.length <= this.charLimit
    this.initialExpanded = this.readLessContent?.length <= this.charLimit
  }
}

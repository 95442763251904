
import { IPhoto } from '@/types/photos'
import { ICustomTab } from '@/types/projects'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ReadMoreBtn from "@/components/explore/hds/ReadMoreBtn.vue"
import ImageSlider from '@/components/explore/ImageSlider.vue'
@Component({
  name: 'hds-tab',
  components: {
    ReadMoreBtn,
    ImageSlider
  }
})
export default class HdsTab extends Vue {
  @Prop() customTab!: ICustomTab
  @Prop() currentImages!: IPhoto[]
  @Prop() activeTabKey!: string
  @Prop() tabKey!: string

  isActive = false

  @Watch('activeTabKey')
  onActiveTabChanged (newVal: string): void {
    this.isActive = this.tabKey === newVal
  }

  activateTab (): void {
    this.$emit("tabActivated", this.tabKey)
  }
}


import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import SfAlert from '@/components/SfAlert.vue'
import UserMixin from '@/mixins/UserMixin'
import { ExploreModule } from '@/store/modules/explore'
import { ICurrencyDisplaySettings, IProjectDetailSettings } from '@/types/cms'
import { ICoFunding } from '@/types/finances'
import { IContactPerson } from '@/types/foerderApp'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail, IDonationResult } from '@/types/projects'
import { IVoteResponse } from '@/types/votings'
import { API_URLS } from "@/utils/helpers"

import DonationSuccessModal from '../modals/DonationSuccessModal.vue'

import CoFundingInfo from './CoFundingInfo.vue'
import ContactPerson from './ContactPerson.vue'
import DonationInfo from './DonationInfo.vue'
import InfoArea from './InfoArea.vue'
import PrivateDonationArea from './PrivateDonationArea.vue'
import ProjectOverview from './ProjectOverview.vue'
import SocialMediaArea from './SocialMediaArea.vue'
import SupportProjectArea from './SupportProjectArea.vue'
import VotingArea from './VotingArea.vue'
import VotingInfo from './VotingInfo.vue'
import ProjectOverviewFoerderapp from './hds/ProjectOverviewFoerderapp.vue'
import SocialMediaAreaFoerderApp from './hds/SocialMediaAreaFoerderApp.vue'

@Component({
  name: 'project-detail',
  components: {
    ProjectOverview,
    ProjectOverviewFoerderapp,
    DonationInfo,
    VotingArea,
    SupportProjectArea,
    VotingInfo,
    SfAlert,
    PrivateDonationArea,
    ContactPerson,
    SocialMediaArea,
    SocialMediaAreaFoerderApp,
    CoFundingInfo,
    InfoArea,
    DonationSuccessModal
  }
})
export default class ExploreDetail extends Mixins(UserMixin) {
  @Prop({ default: false }) isOrganization!: boolean
  project: IExploreProjectDetail | IExploreOrganizationDetail = null
  newVotes = 0
  activeTabKey = ""
  donationResult: IDonationResult = null
  donationSuccessModalOpen = false

  get currentContactPerson (): IContactPerson {
    if (!this.project || !('contact_person' in this.project)) {
      return null
    }
    let contactPerson = null
    if (this.activeTabKey === "key_organization") {
      contactPerson = this.project.organization?.contact_person
    } else if (this.activeTabKey === "key_project") {
      contactPerson = this.project.contact_person
    } else {
      contactPerson = this.project.funding_scope?.contact_person
    }
    return contactPerson || null
  }

  get projectDetailSettings (): IProjectDetailSettings {
    return ExploreModule.projectDetailSettings
  }

  get coFundings (): ICoFunding[] {
    return ExploreModule.coFundings
  }

  get isPreview (): boolean {
    return !!this.$route.query?.preview_token
  }

  get showDonationInfo (): boolean {
    if (this.project?.is_status_archived) {
      return this.projectDetailSettings?.displayArchivedCoinBalance !== 3
    }
    return this.projectDetailSettings?.displayCurrentCoinBalance !== 3
  }

  get showSupportArea (): boolean {
    if (this.projectDetailSettings?.enableFoerderApp) {
      return false
    }

    if (!this.project) {
      return false
    }

    if ('expired' in this.project) {
      if (this.project.is_status_archived || this.project.expired || this.project.is_finished) {
        return false
      }
    } else if (this.project?.is_status_archived) {
      return false
    }

    return true
  }

  get showVotingArea (): boolean {
    if (this.projectDetailSettings?.enableFoerderApp) {
      return false
    }
    if (!this.project) {
      return false
    }
    if (!this.project?.voting) {
      return false
    }
    if (
      !this.projectDetailSettings?.enableVoting ||
      this.isPreview ||
      this.project?.is_status_archived ||
      ('expired' in this.project && this.project.expired)
    ) {
      return false
    }
    return true
  }

  get currencyDisplay (): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  get showVotingInfo (): boolean {
    if (!this.project?.voting || !this.projectDetailSettings?.displayCurrentVotingRank) {
      return false
    }
    return this.$moment(this.project.voting.start_date) < this.$moment()
  }

  handleTabChanged (tabKey: string): void {
    this.activeTabKey = tabKey
  }

  formatCurrency (val: number): string {
    const displayName = this.currencyDisplay.useCurrency ? this.currencyDisplay.currencySymbol : this.currencyDisplay.coinNamePlural
    return `${val} ${displayName}`
  }

  updateNavBalance (): void {
    document.querySelectorAll<HTMLElement>('.coin-balance').forEach(balance => {
      balance.innerText = this.currencyDisplay.useCurrency ? this.userProfile.balance.in_currency_display : this.formatCurrency(this.userProfile.balance.in_coins)
    })
  }

  updateUserBalance (): void {
    this.fetchUserBalance().then(() => {
      this.updateNavBalance()
    })
  }

  addVotes (voteRes: IVoteResponse): void {
    if (!voteRes.verification_needed) {
      this.newVotes += voteRes.new_votes
    }
  }

  async updateNavbar (): Promise<void> {
    await axios.get(API_URLS.V3.TOPBAR_DATA).then(res => {
      document.querySelector<HTMLElement>('.adjust-navbar').innerHTML = res.data.template
    })
    this.updateNavBalance()
    this.loadProject()
  }

  onDonation (result): void {
    this.donationResult = result
    this.loadProject()
    this.updateUserBalance()
    this.donationSuccessModalOpen = !this.donationSuccessModalOpen
  }

  onSuccessModalClose (): void {
    this.donationResult = null
    this.donationSuccessModalOpen = !this.donationSuccessModalOpen
  }

  async loadProject (): Promise<void> {
    this.$wait.start('load project')
    let previewToken = this.$route.query?.preview_token
    if (Array.isArray(previewToken)) {
      previewToken = previewToken[0]
    }

    let url = ''
    if (this.isOrganization) {
      url = API_URLS.EXPLORE.ORGANIZATIONS.RETRIEVE(this.$route.params.orgSlug)
      if (previewToken) {
        url = API_URLS.EXPLORE.ORGANIZATIONS.PREVIEW(this.$route.params.orgSlug, previewToken)
      }
    } else {
      url = API_URLS.EXPLORE.PROJECTS.RETRIEVE(this.$route.params.projectSlug)
      if (previewToken) {
        url = API_URLS.EXPLORE.PROJECTS.PREVIEW(this.$route.params.projectSlug, previewToken)
      }
    }

    if (ExploreModule.projectDetailSettings) {
      await axios.get(url).then(res => {
        this.project = res.data
      })
    } else {
      await Promise.all([
        ExploreModule.fetchProjectDetailSettings(),
        ExploreModule.fetchCofundings(),
        axios.get(url).then(res => {
          this.project = res.data
        })
      ])
    }

    this.$wait.end('load project')
  }

  async created (): Promise<void> {
    this.fetchUserProfile()
    await this.loadProject()

    document.title = `${this.project.title} - ${this.projectDetailSettings?.siteName}`
  }
}

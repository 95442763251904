
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IPhoto, IVideoProviders, IVideoProvider } from '@/types/photos'
import { setCookie } from '@/utils/helpers'

@Component({
  name: 'video-component'
})
export default class VideoComponent extends Vue {
@Prop({ required: true }) photo: IPhoto

videoProviders: IVideoProviders = {
  youtube: {
    name: "Youtube",
    cookieName: "youtube",
    cookieMsg: this.$gettext("YouTube cookies are not accepted"),
    acceptCookieMsg: this.$gettext("Activate YouTube")
  },
  streamio: {
    name: "Streamio",
    cookieName: "streamio",
    cookieMsg: this.$gettext("Streamio cookies are not accepted"),
    acceptCookieMsg: this.$gettext("Activate Streamio")
  },
  vimeo: {
    name: "Vimeo",
    cookieName: "vimeo",
    cookieMsg: this.$gettext("Vimeo cookies are not accepted"),
    acceptCookieMsg: this.$gettext("Activate Vimeo")
  },
  mozaik: {
    name: "Mozaik",
    cookieName: "mozaik",
    cookieMsg: this.$gettext("Mozaik cookies are not accepted"),
    acceptCookieMsg: this.$gettext("Activate Mozaik")
  }
}

@Watch("photo")
handlePhotoChanged () {
  this.showVideo = false
}

get videoProvider (): IVideoProvider {
  return this.videoProviders[this.photo.video_provider]
}

set videoProvider (provider) {
  this.$emit("provider", provider)
}

showCookieBanner = false
showVideo = false

gotoVideo (): void {
  const cookieValues = this.$cookies.get('cookie_rule_detail')
  if (!cookieValues || !cookieValues.includes(this.videoProvider.cookieName)) {
    this.showCookieBanner = true
  } else {
    this.showCookieBanner = false
    this.showVideo = true
  }
}

addCookie (): void {
  const existingCookie = this.$cookies.get('cookie_rule_detail')
  const requiredCookieValue = this.videoProvider.cookieName

  if (existingCookie) {
    const updatedValue = `${existingCookie},${requiredCookieValue}`
    setCookie("cookie_rule_detail", updatedValue)
  } else {
    setCookie("cookie_rule_detail", requiredCookieValue)
  }

  this.showCookieBanner = false
  this.showVideo = true
}
}

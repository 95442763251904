
import { TGenericObject } from '@/types/base'
import { IPrivateDonationForm } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'
import EmailInput from './EmailInput.vue'
import AddressInput from './AddressInput.vue'
import { IProjectDetailSettings } from '@/types/cms'
@Component({
  name: 'private-donation-form',
  components: {
    EmailInput,
    AddressInput
  }
})
export default class PrivateDonationForm extends Vue {
  @Prop({ required: false }) form: IPrivateDonationForm
  @Prop() formErrors!: TGenericObject
  @Prop() projectDetailSettings!: IProjectDetailSettings

  get privateDonationForm () {
    return this.form
  }

  set privateDonationForm (value: IPrivateDonationForm) {
    this.$emit("input", value)
  }

  errorState (field: string): boolean {
    return !this.formErrors[field]
  }
}


import { Component, Mixins, Prop } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'
import { IPageSettings } from '@/types/cms'
import { IExploreProjectMinimal } from '@/types/projects'

@Component({
  name: 'project-card'
})
export default class ProjectCard extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectMinimal
  @Prop() pageSettings: IPageSettings

  getProjectPercentage (project: IExploreProjectMinimal): number {
    return Math.floor((project.balance / project.goal) * 100)
  }

  get balance (): string {
    const { balance } = this.project

    return this.pageSettings.currencyDisplay.useCurrency
      ? this.toCurrency(balance, { currency: this.pageSettings.currencyDisplay.currencyIsoCode })
      : this.toCoins(balance, this.pageSettings.currencyDisplay.factorOfCurrencyToCoin)
  }

  truncate (title: string, length: number): string {
    if (title.length > length) {
      return `${title.slice(0, length - 3)} ...`
    }
    return title
  }

  openProject () {
    this.$router.push({ name: 'project-detail', params: { projectSlug: this.project.slug } })

    if (!this.pageSettings.singlePage) {
      location.reload()
    }
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'read-more-collapse'
})
export default class ReadMoreCollapse extends Vue {
  @Prop({ required: true }) btnTxt: string
  @Prop({ required: false, default: "info-icon" }) btnClass: string

  expanded = false
}

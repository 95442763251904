
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'sf-alert'
})
export default class CopyURLButton extends Vue {
  showCopied = false

  copyURL (): void {
    navigator.clipboard.writeText(window.location.href)
    new Promise(resolve => {
      this.showCopied = true
      setTimeout(resolve, 4000)
    }).then(() => {
      this.showCopied = false
    })
  }
}

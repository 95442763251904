
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IContactPerson } from '@/types/foerderApp'
import ReadMoreCollapse from './ReadMoreCollapse.vue'
import VideoComponent from './VideoComponent.vue'
@Component({
  name: 'contact-person',
  components: {
    ReadMoreCollapse,
    VideoComponent
  }
})
export default class ContactPerson extends Vue {
  @Prop({ required: true }) contactPerson: IContactPerson

  get contactPersonPhoto () {
    if (this.contactPerson?.photos.length > 0) {
      return this.contactPerson?.photos[0]
    }
    return null
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'page-size-select'
})
export default class PageSizeSelect extends Vue {
  @Prop({ default: 25 }) value!: number

  get inputModel (): number | string {
    return this.value
  }

  set inputModel (val: number | string) {
    this.$emit('input', val)
  }

  pageSizeOptions = {
    10: 10,
    25: 25,
    50: 50,
    100: 100
  }
}
